import {
  Directive,
  HostBinding,
  HostListener,
  Output,
  EventEmitter,
  inject
} from "@angular/core";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface FileHandle {
  file: File,
  url: SafeUrl
}

@Directive({
  selector: "[appDrag]"
})
export class DragDirective {
  @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();

  @HostBinding("style.background") private background = "rgba(0, 0, 0, 0)";

  private sanitizer: DomSanitizer = inject(DomSanitizer);

  constructor() { }

  @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "rgba(0, 0, 0, 0.1)";
  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "rgba(0, 0, 0, 0)";
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    var allowedExtensions = /(\.zip)$/i; // add params


    this.background = 'rgba(0, 0, 0, 0)';

    let files: FileHandle[] = [];
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      const file = evt.dataTransfer.files[i];
      if(allowedExtensions.exec(file.name)){
        const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
        files.push({ file, url });
      } else {
        // throw error
      }
    }
    if (files.length === 1) { // one file
      this.files.emit(files);
    }
  }
}
