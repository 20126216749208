import { Component, Input, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-wbce-sidenav',
  templateUrl: './wbce-sidenav.component.html',
  styleUrls: ['./wbce-sidenav.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('*', style({ 'overflow-x': 'hidden' })),
      state('void', style({ 'overflow-x': 'hidden' })),
      transition('* => void', [
        style({ width: '*' }),
        animate(250, style({ width: 0 }))
      ]),
      transition('void => *', [
        style({ width: '0' }),
        animate(250, style({ width: '*' }))
      ])
    ])
]
})
export class WbceSidenavComponent implements OnInit {

  openNavBar : boolean = true;

  @Input()
  public set menuOpened(value: boolean) {
    let alignement;
    if(value){
     alignement = "col 3"
    } else {
      alignement = "col 1"
    }
    document.documentElement.style.setProperty('--alignementLeft', alignement);
    this.openNavBar = value;
  }


  @Input() integrated:boolean;

  constructor() { }

  ngOnInit(): void {
  }

  toggle(){
    this.menuOpened=!this.menuOpened;
  }

}
