import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum informationType {
  NO_MESSAGE,
  SESSION_EXPIRED,
  KEYCLOAK_NOT_INIT,
  API_DOWN
}

@Component({
  selector: 'app-informations-dialog',
  templateUrl: './informations-dialog.component.html',
  styleUrls: ['./informations-dialog.component.scss']
})
export class InformationsDialogComponent {

  informationType = informationType;

  public data: informationType = inject(MAT_DIALOG_DATA);
  private dialogRef: MatDialogRef<InformationsDialogComponent> = inject(MatDialogRef<InformationsDialogComponent>);

  constructor() {}

  reloadPage() {
    window.location.reload();
    this.dialogRef.close(); // Close the dialog after reloading the page
  }

}
