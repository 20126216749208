import { Component, inject, OnInit } from '@angular/core';
import { MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  public dialogRef: MatDialogRef<InfoDialogComponent> = inject(MatDialogRef<InfoDialogComponent>);
  public data: any = inject(MAT_DIALOG_DATA)

  constructor() {}

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
