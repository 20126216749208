import { inject, Injectable } from '@angular/core';
import {  Observable, ReplaySubject, Subscription, of } from 'rxjs';
import { AuthService} from './auth.service';
import { User } from '../models/user';
import { Project } from '../models/project';
import { contexteAffichage, contextes } from '../enums/contextes';
import { Service, deploymentStates } from '../models/service';
import { Alert, AlertCode, AlertType, AlerteurService } from './alerteur.service';
import { technos } from '../enums/technos';
import { API_SERVICE_TOKEN } from './api.service';
import { map } from 'rxjs/operators';
import { errorCodes } from '../enums/error-codes';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceManagerService {

  subs : Subscription[] = [];
  projects : Project[] = [];
  wbceproject : Project;
  workspaceName : string = "My workspace";
  currentUser : User;

  nbMaxProject:number=4;

  projects$ = new ReplaySubject(1);

  intervals : any[] = [];
  intervalsFreq: number = 10*1000;


  private auth=inject(AuthService);
  private alerteur=inject(AlerteurService);
  private api = inject(API_SERVICE_TOKEN);

  constructor(){
    this.subs.push(
      this.auth.currentUser$.subscribe(
        this.onUserChange.bind(this)
      )
    )

    this.intervals.push( setInterval(() => {
      this.reloadProjects().subscribe()
    }, this.intervalsFreq));

  }

  ngOnDestroy(){
    for(let s of this.subs){
      s.unsubscribe();
    }
  }

  onUserChange(user : User){
    if(user){
      this.currentUser = user;
      this.projects = user.projects.filter((p)=>(p.projectName!="wbce")&&(p.projectName!="webcapsule")).sort((a, b)=> a._id > b._id ? 1 : -1);
      this.wbceproject = user.projects.find((p)=>(p.projectName==="wbce" || p.projectName=="webcapsule"))
      this.addWorkspaceStatusNotification();

      console.log("notify update project")
      this.projects$.next(this.projects);
    }
  }

  reloadProjects(): Observable<void>{
    console.log("reload project")
    return this.auth.reLoadUser() // reload projects & users
  }

  reloadProject(project: Project) : Observable<void>{
    const index = this.projects.findIndex((p)=> p._id === project._id)
    if(index>=0){
      return this.api.getProject(project).pipe(
        map((res : any)=>{
          this.projects[index] = Project.createFromApi(res?.project);
          this.projects$.next(this.projects);
        }))
      }
    return of();
  }

  openInfisicalService(){
    let serviceInfisical = this.wbceproject.services.find((s)=> s.techno.id == technos.INFISICAL)

    if(serviceInfisical) {
      this.api.getUrl(serviceInfisical, contextes.GLOBAL).subscribe(url=>{
        if(url)window.open(url, '_blank');
      })
    }
  }


  // Add alert
  addServiceStatusNotification(projectName : string, service: Service, contexte:contextes|string){
    let contextDoc = service.getContextDoc(contexte)
    if(!contextDoc) return false;
    let mainCycle = contextDoc.mainCycle;
    let deployments = contextDoc.deployments
    let lastDeployment = deployments && deployments.length>0 && deployments[deployments.length-1]

    let stateText = "";
    let filter = {
      serviceId : service._id,
      contexte : contexte
    }
    let titre = projectName;
    if(service.techno) titre = titre + " - "+ service?.caculatedDisplayName;
    titre = titre  + " - "+contexteAffichage[contexte]
    let addRefreshTimer = false;
    let dontCheckDeploymentState = false;

    if(mainCycle ){ //looking for step
      let message = "Saving a version";
      let alertType = AlertType.ERROR_PERMANENT;
      let updateOnly = true;
      let state = mainCycle.state;
      let suffix = " with a few warnings";

      try {
        const error = JSON.parse(mainCycle.message)
        if(error.code  === errorCodes.NOTHING_TO_COMMIT) {
          state = deploymentStates.WARNING
          suffix = " and nothing to commit."
        }
      } catch (error) {
      }

      switch (state) {
        case deploymentStates.IN_PROGRESS:

          alertType=AlertType.PROGRESS;
          message=message+"."
          addRefreshTimer = true;
          updateOnly = false;

          // for mainCycle : look at deployment state
          if(lastDeployment && lastDeployment.state===deploymentStates.IN_PROGRESS){
            message="Deployment in progress"
            dontCheckDeploymentState = true;
          }

          break;
        case deploymentStates.WARNING:
          alertType=AlertType.WARNING;
          message=message+suffix
        break;
        case deploymentStates.ERROR:
          alertType=AlertType.ERROR_PERMANENT;
          message=message+" failed."
        break;
        case deploymentStates.SUCCESS:
          alertType=AlertType.SUCCESS
          stateText=message+" run successfully";
        break;
        default:
          alertType = AlertType.ERROR_PERMANENT;
          message=message+" failed."
          break;
      }

      let alert = new Alert(titre, AlertCode.ADD, alertType, filter, message);
      if(updateOnly){
        this.alerteur.updateIfExistAlert(alert);
      } else {
        this.alerteur.addOrUpdateAlert(alert);
      }
    }

    if(lastDeployment && !dontCheckDeploymentState){
      let alertType = AlertType.ERROR_PERMANENT;
      let updateOnly = true;
      let message = "Deployment";

      switch (lastDeployment.state) {
        case deploymentStates.IN_PROGRESS:
          alertType=AlertType.PROGRESS;
          message=message+" in progress."
          addRefreshTimer = true;
          updateOnly = false;
          break;
        case deploymentStates.ERROR:
          alertType=AlertType.ERROR_PERMANENT;
          message=message+" failed."
        break;
        case deploymentStates.SUCCESS:
          alertType=AlertType.SUCCESS
          stateText=message+" run successfully";
        break;
        default:
          alertType = AlertType.ERROR_PERMANENT;
          message=message+" failed."
          break;
      }

      let alert = new Alert(titre, AlertCode.DEPLOY, alertType, filter, message);
      if(updateOnly){
        this.alerteur.updateIfExistAlert(alert);
      } else {
        this.alerteur.addOrUpdateAlert(alert);
      }
    }


    return addRefreshTimer
  }

  //temporary service notification before refreshing
  addTmpServiceStatusNotification(alertCode: AlertCode, projectName : string, service: Service, contexte:contextes, state:deploymentStates){
    let filter = {
      serviceId : service._id,
      contexte : contexte
    }
    let titre = projectName;
    if(service.techno) titre = titre + " - "+ service?.caculatedDisplayName;
    titre = titre + " - "+ contexteAffichage[contexte];
    let subtitle = alertCode === AlertCode.ADD ?  "Starting saving a version.": alertCode === AlertCode.MERGE ? "Starting merging." : "Starting deployment.";
    let alert = new Alert(titre, alertCode, AlertType.PROGRESS, filter,subtitle); // or save dependent of type
    this.alerteur.addOrUpdateAlert(alert);
  }


  addWorkspaceStatusNotification(){ // scan services to add to alert notifications of deployments
    this.projects.forEach((p) =>{
      const ctxts = p.getContextes()
      p.services.forEach((s) =>{
        for (const contexte of ctxts) {
          this.addServiceStatusNotification(p.projectName, s, contexte);
        }
      })
    })
  }

  getProjectName(projectId : string){
     const project = this.projects.find((p)=> p._id === projectId)
     return project?.projectName || projectId
  }

}
