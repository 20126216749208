
<div id="menu-group-grille">
  <div id="menu-group">
      <div id="button-group" [ngClass]="{'button-group-active' : opened}">
        <div matTooltip="Go to home">
          <button class="logo" mat-icon-button (click)="goTo('home')" color="active">
            <mat-icon inline fontSet="fa" fontIcon="fa-house"></mat-icon>
          </button>
        </div>
        <div matTooltip="See documentation">
          <button class="logo" mat-icon-button (click)="goTo('documentation')">
              <mat-icon inline fontSet="fa" fontIcon="fa-book"></mat-icon>
          </button>
        </div>
        <div matTooltip="About webapsule">
          <button class="logo" mat-icon-button (click)="openInfoModal()">
              <mat-icon inline fontSet="fa" fontIcon="fa-circle-info"></mat-icon>
          </button>
        </div>

        <div class="encoche-fine"></div>
        <div (clickOutside)="closeMenu()" class="menu-bloc">
            <button mat-button (click)="toggleMenu()">
                <div class="button-name">
                  <div class="mat-body-2 text-bloc">{{userEmail}}</div>
                  <div class="logo-arrow">
                    <mat-icon inline fontSet="fa" fontIcon="fa-angle-down"></mat-icon>
                  </div>
                </div>
            </button>
        </div>
      </div>

      <div class="menu-list"  *ngIf="opened" [@fadeInOut] >
        <app-wbce-button
           type="menu-button"
           (click)="goTo('account')"
           >
          <div class="mat-body-2 text-bloc">Account management</div>
        </app-wbce-button>
        <app-wbce-button
         type="menu-button"
         role="manage-team"
         (click)="goTo('team')">
          <div class="mat-body-2 text-bloc">Manage team</div>
        </app-wbce-button>
        <div class="split-bar-h"></div>
          <app-wbce-button
          type="menu-button"
          (click)="disconnect()">
          <div class="menu-line-red">
            <div class="mat-body-2 text-bloc">Logout</div>
          </div>
         </app-wbce-button>


      </div>

    </div>

</div>



