import { Component, OnInit, HostListener, inject } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {Router} from "@angular/router";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

export type GoToChoice = "documentation" | "account" | "home" | "team"

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
      trigger('fadeInOut', [
        state('*', style({ 'overflow-y': 'hidden' })),
        state('void', style({ 'overflow-y': 'hidden' })),
        transition('* => void', [
          style({ height: '*' }),
          animate(250, style({ height: 0 }))
        ]),
        transition('void => *', [
          style({ height: '0' }),
          animate(250, style({ height: '*' }))
        ])
      ])
  ]
})


export class MenuComponent implements OnInit {

  opened : boolean = false;

  userEmail : string = "";
  subs : Subscription[] = [];

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.closeMenu();
  }


  public auth : AuthService = inject(AuthService);
  public dialog: MatDialog = inject(MatDialog);
  private router: Router = inject(Router);


  constructor() { }

  ngOnInit(): void {
    this.subs.push(
      this.auth.currentUser$.subscribe(
        this.onUserChange.bind(this)
      )
    )
  }


  ngOnDestroy(){
    for(const s of this.subs){
      s.unsubscribe();
    }
  }

  disconnect(){
    this.auth.disconnect();
  }

  openInfoModal(){
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: '50%',
      height:'25%'
    });
  }


  notImplemented(){
    console.log("not yet implemented")
  }

  goTo(destination:GoToChoice){

    switch (destination) {
      case "documentation":
        const docUrl = 'https://docs.webcapsule.io'
        window.open(docUrl,'_blank')
        break;
      case "account":
        this.router.navigate(['/manage-account'])
        break;
      case "home":
        this.router.navigate(['/workspace'])
        break;
      case "team":
        this.router.navigate(['/manage-workspace/users'])
        break;
      default:
        break;
    }

  }

  toggleMenu(){
    this.opened = !this.opened;
  }

  closeMenu(){
    this.opened = false;
  }

  onUserChange(){
    this.userEmail = this.auth.currentUser?.userInfo?.email
  }

}
