import { Component, Input, OnInit } from '@angular/core';


export enum stepStates{
  waiting = 'waiting',
  in_progress = 'in_progress',
  filled = 'filled'
}


@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {

  @Input() selfStepIndex : number;
  @Input() currentStepIndex;
  @Input() stepLabel;
  
  stepStates = stepStates;

  constructor() {}

  ngOnInit(): void {
  }

  get state(){
    if((this.selfStepIndex-this.currentStepIndex)>0){
      return stepStates.waiting
    }
    else if((this.selfStepIndex-this.currentStepIndex) === 0){
      return stepStates.in_progress
    }
    else{
      return stepStates.filled
    }
  }

}
