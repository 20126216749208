import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule} from '@angular/material/dialog'
import { RouterModule } from '@angular/router';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { MenuComponent } from './menu/menu.component';
import { StepComponent } from './step/step.component';
import { TableComponent } from './table/table.component';
import { ReactiveFormsModule } from '@angular/forms';
import {OverlayModule} from '@angular/cdk/overlay';
import { ClickOutsideDirective } from './directive/clickOutside.directive'; // directive pouvant être revu
import { WbceButtonComponent } from './wbce-button/wbce-button.component';
import {MatRippleModule} from '@angular/material/core';
import { SpinnerComponent } from './spinner/spinner.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { WbceSidenavComponent } from './wbce-sidenav/wbce-sidenav.component';
import { ToasterComponent } from './toaster/toaster.component';
import { DragDirective } from './directive/dragNDrop.directive';
import { NotificationsComponent } from './notifications/notifications.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InformationsDialogComponent } from './session-expired-dialog/informations-dialog.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    HeaderComponent,
    InfoDialogComponent,
    WbceButtonComponent,
    MenuComponent,
    StepComponent,
    WbceSidenavComponent,
    TableComponent,
    ClickOutsideDirective,
    DragDirective,
    SpinnerComponent,
    ToasterComponent,
    NotificationsComponent,
    InformationsDialogComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    RouterModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    OverlayModule,
    MatRippleModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatMenuModule
  ],
  exports : [ClickOutsideDirective, HeaderComponent, StepComponent, TableComponent, WbceButtonComponent, SpinnerComponent, WbceSidenavComponent, ToasterComponent, DragDirective, NotificationsComponent, InformationsDialogComponent]
})
export class SharedComponentsModule { }
