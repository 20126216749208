<section id="container">
  <h1>
      Webcapsule : beta-test
  </h1>
  <div>
      Au cours de la phase de beta-test, différentes fonctionnalités seront délivrées.
  </div>
  <div>
      Vous pouvez nous faire remonter bugs et suggestions en nous écrivant à l'adresse suivante :
      <a href="mailto: infos@webcapsule.io" type="mail">
          infos&#64;webcapsule.io
      </a>
  </div>
</section>
